


























import {Vue, Component} from 'vue-property-decorator'

import Utils from '@/utils'
import {HomeModeEnum, SplashButton, SplashButtons, VehicleType} from '@/lib/kepler/interfaces'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import {Action, Getter, State} from 'vuex-class'
import {FlowInputsState} from '@/store/modules/flowInputs'
import {FilterStructure} from '@/store/modules/filters'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'

@Component({
  components: {
    GradientCard: Utils.loadComponent('GradientCard'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Container: Utils.loadComponent('proxy/Container'),
    CardGrid: Utils.loadComponent('CardGrid'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Button: Utils.loadComponent('Button'),
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
  },
  name: 'Splash',
})
export default class Splash extends Vue {
  @State('flowInputs') public flowInputs!: FlowInputsState

  @Getter('splashButtons') public buttonGroups!: SplashButtons[]
  @Getter('showSplash') public showSplashState!: boolean

  @Action('setHomeMode') public setHomeMode!: (mode: HomeModeEnum) => void
  @Action('_toggle') public toggleFilter!: (path: [string, string]) => void
  @Action('setMapItems') public setMapItems!: () => Promise<void>
  @Action('setSplashShow') protected setSplash!: (b: boolean) => void
  @Action('setCustomFilters') protected setCustomFilters!: (config: FilterStructure) => void

  protected get donotshowagain() {
    return !this.showSplashState
  }

  protected set donotshowagain(v: boolean) {
    this.setSplash(!v)
  }

  protected getIcon(icon?: string) {
    if (icon && icon.toUpperCase() in VehicleType) {
      return {
        name: icon.toLowerCase(),
        vehicle: true,
      }
    } else if (icon) {
      return {name: 'mdi-' + icon.toLowerCase(), vehicle: false}
    }
  }

  protected getAction(b: SplashButton): void {
    let actn: () => void
    switch (b.action) {
      case 'flow':
        actn = () => {
          if (typeof b.options === 'string' && b.options in this.flowInputs) {
            this.openFlow(b.options)
          }
        }
        break
      case 'path':
        actn = () => {
          this.$popup.close()
          this.$router.push(b.options)
        }
        break
      case 'url':
        actn = () => {
          if (typeof b.options === 'string') {
            cordova.InAppBrowser.open(b.options, '_system')
          }
        }
        break
      case 'home_mode':
        actn = () => {
          const mode = b.options
          if (typeof mode === 'string' && mode in HomeModeEnum) {
            this.setHomeMode(mode as HomeModeEnum)
            this.$popup.close()
          }
        }
        break
      case 'info':
        actn = () => {
          const opt = b.options as { title: string, text: string }|string
          let title = ''
          let subtitle = ''
          if (typeof opt === 'string') {
            subtitle = opt
          } else if ('text' in opt || 'title' in  opt) {
            title = opt.title
            subtitle = opt.text
          }

          this.$dialog.open(ConfirmDialogCallback, {
            props: {
              singleAction: true,
              imageState: '',
              code: '',
              title,
              subtitle,
              confirmText: this.$t('action.close'),
            },
          })
        }
        break
      case 'filters':
        actn = () => {
          const opts = b.options
          if (typeof opts === 'object') {
            this.setCustomFilters(opts as FilterStructure)
            this.$popup.close()
          }
        }
        break
      default:
        actn = () => {
          // lol
        }
    }
    actn()
  }

  protected buttonSize(p?: boolean | number) {
    let gridColumnStart: string | null = null
    if (typeof p === 'number') {
      gridColumnStart = `span ${p}`
    } else if (p) {
      gridColumnStart = 'span 2'
    }
    return {gridColumnStart}
  }

  protected parseFilters(filterString: string) {
    this.setMapItems().then(() => {
      const params = new URLSearchParams(filterString)
      params.forEach((value, key) => {
        value.split(',').forEach((v) => {
          this.toggleFilter([key, v])
        })
      })
    })
  }

  protected openFlow(flowName?: string) {
    if (flowName && flowName in this.flowInputs) {
      const flow = this.flowInputs[flowName]
      const flowHasSteps = () => {
        const allHidden = flow.steps.every((s) => s.skip)
        return !(!flow.steps?.length || allHidden)
      }
      if (flow && flowHasSteps()) {
        this.$popup.open(FlowAutoload, {props: {flowName}, hideTopbar: true})
      }
    }
  }
}
